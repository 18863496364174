//@import "~bootstrap/scss/bootstrap-reboot";
@import "node_modules/bootstrap/scss/bootstrap-grid";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

//$fa-font-path: "~font-awesome/fonts";
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import "scss/colors";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap";

body {
    font-family: Montserrat, sans-serif;
    overflow-x: hidden;

    .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        video {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1000;
            overflow: hidden;
            object-fit: fill;
        }

        .blur {
            position: absolute;
            inset: 0;
            z-index: -900;
            backdrop-filter: blur(15px);
        }
    }

    @media screen and (aspect-ratio <= 1920/1080) {
        .video-background > video {
            height: 100%;
        }
    }

    @media screen and (aspect-ratio >= 1920/1080) {
        .video-background > video {
            width: 100%;
        }
    }

    h1 {
        font-size: 2.5em;
    }

    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.2em;
    }

    .fullwidth {
        width: 100%;
    }

    .fullheight {
        height: 100%;
    }

    .align-right {
        text-align: right !important;
    }

    .align-left {
        text-align: left !important;
    }

    .align-center {
        text-align: center !important;
    }

    .valign-middle {
        vertical-align: middle;
    }

    .valign-top {
        vertical-align: top;
    }

    .valign-bottom {
        vertical-align: bottom;
    }

    .valign-sub {
        vertical-align: sub;
    }

    .inline-block {
        display: inline-block;
    }

    .clickable{
        cursor: pointer;
    }

    button {
        &:focus {
            outline: none;
        }

        &.big {
            font-size: 1.5em;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &.green {
            background-color: $green;
            color: white;
        }

        &.neutral {
            background-color: $neutral;
            color: white;
        }

        &.red {
            background-color: $red;
            color: white;
        }
    }
}
