$green: #5eaf39;
$yellow: #d3c436;
$red: #ff3087;
$purple: #ff6bd3;
$neutral: #76c5e0;
$grey: #333;
$light-grey: #a2a2a2;
$dark-red: #d20b0b;
$dark-green: #239e04;
$dark-background: #101421;
$main-color-1: #338fb6;
$main-color-2: #b63383;
$main-color-3: #77b633;

i.red,
mat-icon.red,
.red-text {
    color: $red;
}

i.green,
mat-icon.green,
.green-text {
    color: $green;
}

i.yellow,
mat-icon.yellow,
.yellow-text {
    color: $yellow;
}

i.neutral,
mat-icon.neutral,
.neutral-text {
    color: $neutral;
}
